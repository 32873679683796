import type { ActionProps } from '@/components/actions';
import { axiosClient } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { OrderWrite } from '@/data/commerce/order.graphql';
import isOrderSyncedToClover from '@/helpers/useCheckIsSyncedToClover';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { MutationOrderWriteArgs, Order } from '@/types/schema';
import { SettingsBackupRestore as SettingsBackupRestoreIcon } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function useDeletedOrderActions( order: Order ) {
	const { t } = useTranslation();
	const editable = usePermissions( permissions.orders.write );
	const confirmDialog = useConfirmDialog();
	const queryClient = useQueryClient();
	const syncedToClover = isOrderSyncedToClover( order );
	const { staff } = useUserInfo();
	
	return [
		editable && {
			name   : t( 'common:restore' ),
			icon   : <SettingsBackupRestoreIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'common:recover' ),
					message: t( 'common:are-you-sure-you-want-to-recover' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationOrderWriteArgs>( {
					mutation : OrderWrite,
					variables: {
						id    : order.id,
						method: 'Recovered',
						input : { deletedAt: null },
					},
				} );
				if ( syncedToClover ) {
					try {
						await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
							id     : order.id,
							staffId: staff?.id,
						} );
					} catch {
					}
				}
				await queryClient.invalidateQueries( [ 'order' ] );
				
			},
		},
	] as ActionProps[];
}
