import { useFetchCloverOrderTypes } from '@/hooks/useFetchCloverOrderTypes';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { isEmpty, toLower, upperFirst } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

export default function useOrdersQuickFilterActions() {
	const { t } = useTranslation();
	const router = useRouter();
	const event = useEvents();
	const creatable = usePermissions( permissions.orders.write );
	const { staff } = useUserInfo();
	const [ loading, ordertypes, orderTypesLabels ] = useFetchCloverOrderTypes( undefined, staff.company.id );
	
	return [
		creatable && {
			name   : t( 'common:view-open-orders' ),
			onClick: () => {
				router.push( {
					pathname: router.asPath.split( '?' )[ 0 ],
					query   : {
						s: encodeURIComponent( btoa( JSON.stringify( {
							filters: [ {
								id   : 'status',
								value: {
									$in : [ 'SENT', 'DRAFT', 'VIEWED', 'PARTIALLY_PAID' ],
									temp: { checked: true },
								},
							} ],
						} ) ) ),
					},
				}, undefined, { shallow: true } ).then();
				event.emit( 'url.filter', true );
			},
		}, {
			name   : t( 'common:recurring-orders' ),
			onClick: () => {
				router.push( {
					pathname: router.asPath.split( '?' )[ 0 ],
					query   : {
						s: encodeURIComponent( btoa( JSON.stringify( {
							filters: [ {
								id   : 'status',
								value: {
									$eq : 'STANDING',
									temp: { checked: true },
								},
							} ],
						} ) ) ),
					},
				}, undefined, { shallow: true } ).then();
				event.emit( 'url.filter', true );
			},
		},
		{
			name   : t( 'common:online-orders' ),
			onClick: () => {
				router.push( {
					pathname: router.asPath.split( '?' )[ 0 ],
					query   : {
						s: encodeURIComponent( btoa( JSON.stringify( {
							filters: [ {
								id   : 'store',
								value: {
									id  : { $ne: null },
									temp: { checked: true },
								},
							} ],
						} ) ) ),
					},
				}, undefined, { shallow: true } ).then();
				event.emit( 'url.filter', true );
			},
		},
		...!isEmpty( orderTypesLabels ) ? orderTypesLabels.map( ( label ) => ( {
			name   : upperFirst( toLower( label ) ),
			onClick: () => {
				router.push( {
					pathname: router.asPath.split( '?' )[ 0 ],
					query   : {
						s: encodeURIComponent( btoa( JSON.stringify( {
							filters: [ {
								id   : 'serviceType',
								value: {
									$eq : label,
									temp: { checked: true },
								},
							} ],
						} ) ) ),
					},
				}, undefined, { shallow: true } ).then();
				event.emit( 'url.filter', true );
			},
		} ) ) : [],
	];
}

