import Attachment from '@/components/fileUploading/attachment';
import { lineItemsStatusColors } from '@/components/graphqlTable/getDeliveryStatusRowStyling';
import { mutateGraphQL } from '@/data/apollo';
import { CompanyTagsType } from '@/modals/companyTags';
import { InvoiceLineItemsReadGQL } from '@/pages/dashboard/commerce/invoices/invoiceGQL';
import { useMenu } from '@/providers/menu';
import type { LineItemMultiOutput, MutationLineItemWriteArgs, Order, QueryLineItemsReadArgs } from '@/types/schema';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import {
	IconButton,
	ListItem,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { LineItemWrite } from '../../../../data/management/lineItem.graphql';
import GraphqlProvider from '../../../../data/query/graphqlProvider';
import currencyFormat from '../../../../helpers/currencyFormat';

export default function RowPanel( { row, hideTags, darkMode }: {
	row: Order,
	hideTags?: boolean,
	darkMode?: boolean
} ) {
	const { t } = useTranslation();
	const { showMenu } = useMenu();
	const lineItemTags: CompanyTagsType[] = row.company?.tags?.lineItemTags?.filter( ( tag ) => tag?.forOrder ) || [];
	const lineItemsTagsNames = lineItemTags?.map( ( tag ) => tag.name ) || [];
	const colorsMap = lineItemsStatusColors( lineItemTags, darkMode );
	
	return (
		<GraphqlProvider<LineItemMultiOutput, QueryLineItemsReadArgs>
			queryKey='lineItems'
			query={InvoiceLineItemsReadGQL}
			subscription={{ LINE_ITEM: row.id }}
			variables={{ options: { filter: { order: row.id } } }}>
			{( { items: lineItems } ) => (
				<Paper sx={{ width: '100%', maxHeight: 400, overflow: 'overlay', border: 0 }}>
					{lineItems.length ? lineItems.map( ( lineItem ) => (
						<ListItem
							key={lineItem.id}
							sx={{
								'px'                                : 1,
								'py'                                : .5,
								':not(.MuiListItem-root:last-child)': {
									borderBottom: 1,
									borderColor : 'divider',
								},
							}}>
							<ListItemText
								secondaryTypographyProps={{ component: 'div' }}
								primaryTypographyProps={{ component: 'div' }}
								primary={(
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography variant='subtitle2'>{lineItem.name}</Typography>
										<Typography>{lineItem.quantity} {lineItem.unit}</Typography>
										<Typography>x</Typography>
										<Typography>{currencyFormat( lineItem.price )}</Typography>
									</Stack>
								)}
								secondary={lineItem?.prices?.filter( ( price ) => !price.metadata?.externalTax )
									?.map( ( fee ) => (
										<Typography key={fee.id}>
											{`${fee.name} ${fee.isPercent
												? `(${fee.value}%)`
												: ` - ${currencyFormat( fee.value )}`}`}
										</Typography>
									) )}
							/>
							<Stack direction='row' spacing={1} alignItems='center'>
								<Typography>
									{currencyFormat( lineItem.price * lineItem.quantity
										+ lineItem?.prices.reduce( ( values, fee ) => fee.value + values, 0 ) )}
								</Typography>
								<Tooltip title={lineItem.status || ''}>
									<Typography
										sx={{
											color: colorsMap?.[ lineItem.status ]
												? `${colorsMap[ lineItem.status ]} !important`
												: undefined,
										}}>
										{hideTags ? '' : lineItem.status || t( 'commerce:add-status' )}
									</Typography>
								</Tooltip>
								{!hideTags && !isEmpty( lineItemsTagsNames ) && (
									<IconButton
										onClick={( e ) => showMenu( ( { closeMenu } ) => (
											<MenuList sx={{ p: 0 }}>
												{lineItemsTagsNames.map( ( status, index ) => (
													<MenuItem
														key={index}
														sx={{ my: 0 }}
														selected={lineItem.status === status}
														onClick={async () => {
															await mutateGraphQL<MutationLineItemWriteArgs>( {
																mutation : LineItemWrite,
																variables: {
																	id   : lineItem.id,
																	input: { status: lineItem.status === status ? null : status },
																},
															} );
															closeMenu();
														}}>
														{status}
													</MenuItem>
												) )}
												<MenuItem
													sx={{ my: 0 }}
													onClick={async () => {
														window.open( '/dashboard/settings/doc?tab=general', '_blank' );
														closeMenu();
													}}>
													{t( 'settings:create-or-edit-tags' )}
												</MenuItem>
											</MenuList>
										), e.currentTarget )}>
										<MoreHorizIcon/>
									</IconButton>
								)}
							</Stack>
						</ListItem>
					) ) : <Typography color='text.secondary' p={.5}>{t( 'common:no-items' )}</Typography>}
					<Stack direction='row' alignItems='center' spacing={1} p={1}>
						{row.metadata?.signature && (
							<Stack spacing={1}>
								<Typography>{t( 'commerce:signed' )}</Typography>
								<Attachment
									whiteBg
									removeDownload
									src={row.metadata.signature}
									imageSX={{ width: 40, height: 40, objectFit: 'cover', mb: 0 }}
								/>
							</Stack>
						)}
						{row.deliveryStatusNote && (
							<Typography color='text.secondary' p={1} sx={{ whiteSpace: 'pre-line' }}>
								{t( 'commerce:note' )} {row.deliveryStatusNote}
							</Typography>
						)}
					</Stack>
					{row.metadata?.privateNote && (
						<Typography color='text.secondary' p={1}>
							{`${t( 'commerce:private-note' )} ${row.metadata.privateNote}`}
						</Typography>
					)}
				</Paper>
			)}
		</GraphqlProvider>
	);
}
